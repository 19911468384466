import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email-templates-configure',
  templateUrl: './email-templates-configure.component.html',
  styleUrls: ['./email-templates-configure.component.scss']
})

export class EmailTemplatesConfigureComponent implements OnInit {
  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dateToday: string;
  emailHeader: string;
  emailContent: string;
  linkToButton: string;
  textOnButton: string;
  twitterLink: string;
  facebookLink: string;
  instagramLink: string;
  linkedinLink: string;
  emailTemplateForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    let time = new Date();
    this.dateToday =  this.monthNames[time.getMonth()] + ' ' + time.getDate() + ', ' + time.getFullYear();
    this.emailHeader = 'Email Header will go here'
    this.emailContent = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    this.linkToButton = 'javascript:void(0)'
    this.textOnButton = 'Check Out'
    this.twitterLink = 'javascript:void(0)'
    this.facebookLink = 'javascript:void(0)'
    this.instagramLink = 'javascript:void(0)'
    this.linkedinLink = 'javascript:void(0)'

    this.emailTemplateForm = new FormGroup({
      textOnButton: new FormControl(this.textOnButton),
      linkToButton: new FormControl(this.linkToButton),
      twitterLink: new FormControl(this.twitterLink),
      facebookLink: new FormControl(this.facebookLink),
      instagramLink: new FormControl(this.instagramLink),
      linkedinLink: new FormControl(this.linkedinLink),
    });
  }

  updateTemplate() {
    let emailConfiguration = this.emailTemplateForm.value;
    this.textOnButton = emailConfiguration.textOnButton;
    this.linkToButton = emailConfiguration.linkToButton;
    this.twitterLink = emailConfiguration.twitterLink;
    this.facebookLink = emailConfiguration.facebookLink;
    this.instagramLink = emailConfiguration.instagramLink;
    this.linkedinLink = emailConfiguration.linkedinLink;
  }

}
