import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { LayoutModule } from '../../../layouts/layout.module';
import { DefaultComponent } from '../default.component';
import { KpiComponent } from './kpi/kpi.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastrModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { ChartsModule } from 'ng2-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AngularGradientProgressbarModule } from 'angular-gradient-progressbar';
import { ReferralStatisticsComponent } from './referral-statistics/referral-statistics.component';
import { RevenueStatisticsComponent } from './revenue-statistics/revenue-statistics.component';
import { ProductUsageStatisticsComponent } from './product-usage-statistics/product-usage-statistics.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { ReplaceUnderscorePipe } from './kpi/replace-underscore.pipe';
const routes: Routes = [
    {
        "path": "",
        "component": DefaultComponent,
        "children": [

            {
                "path": "kpi",
                "component": KpiComponent
            },
            {
                "path": "referral",
                "component": ReferralStatisticsComponent
            },

            {
                "path": "revenue",
                "component": RevenueStatisticsComponent
            },

            {
                "path": "productUsage",
                "component": ProductUsageStatisticsComponent
            }
        ]
    }
];
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        LayoutModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        MatSortModule,
        ToastrModule.forRoot(),
        NgxCaptchaModule,
        MatMenuModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTabsModule,
        MatTableModule,
        ChartsModule,
        AngularGradientProgressbarModule,
        MatProgressBarModule,
        MatDialogModule
    ], exports: [
        RouterModule
    ], declarations: [
        DashboardComponent,
        KpiComponent,
        ReferralStatisticsComponent,
        RevenueStatisticsComponent,
        ProductUsageStatisticsComponent,
        ReplaceUnderscorePipe,
    ]
})
export class DashboardModule {

}