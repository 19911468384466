import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getActiveHomeBuyerCount() {
    return this.http.get<any>('admin/getcount/activeHomeBuyerCount');
  }
  getActiveHomeBuyerStages() {
    return this.http.get<any>('admin/getcount/homeBuyerStages');
  }
  getCountOfServiceProvider() {
    return this.http.get<any>('admin/getcount/serviceProviderCount');
  }
  getCountOfSpCategory() {
    return this.http.get<any>('admin/getcount/spCategory');
  }
  getCountOfHomeBuyerSpCategory() {
    return this.http.get<any>('admin/getcount/homeBuyerCountPerSpCategory');
  }
  getCountOfSpPlans() {
    return this.http.get<any>('admin/getcount/spPlans');
  }
  getServiceProviderRefferal() {
    return this.http.get<any>('admin/getcount/ServiceProviderReferralMade');
  }
  getServiceHomeBuyerRefferal() {
    return this.http.get<any>('admin/getcount/homeBuyerReferralMade');
  }
  getStatisticsTable() {
    return this.http.get<any>('business-category/statistics');
  }
  // getspstatsTable(refresh) {
  //   console.log("refresh", refresh)
  //   refresh = encodeURIComponent(refresh);
  //   return this.http.get<any>(`admin/getcount/spstatsTable/${refresh}`);
  // }
}
