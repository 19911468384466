import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit {
  emailTemplates: string[] = []

  constructor() { }

  ngOnInit(): void {
    this.emailTemplates = ['Welcome Email', 'Storefront Featured', 'New Services Added']
  }
}
