import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';


@Injectable()
export class DefaultService {



  // Observable string sources
  private newShowSource = new Subject<boolean>();

  // Observable string streams
  newShow = this.newShowSource.asObservable();

  // Observable string streams
  updatePic = this.newShowSource.asObservable();

  constructor(private http: HttpClient) { }

  changeShow(show: boolean) {

    this.newShowSource.next(show);
  }

  updateProfile(update: boolean) {
    this.newShowSource.next(update);

  }


  // getLoggedInUser() {
  //   let charityId = localStorage.getItem('charityId');
  //   let adminId = localStorage.getItem('UserId');

  //   //return this.http.get<NgoUserResponse>('charities/' + charityId + '/admin/' + adminId);
  // }

}
