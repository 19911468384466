import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';

import { DefaultService } from '../theme/pages/default/default.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private defaultService: DefaultService, private authService: AuthService) { }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  public canActivate(): boolean {
    let userId = sessionStorage.getItem('userId');
    if (sessionStorage.getItem('userId') === null) {
      this.router.navigate(['/login']);
      return false;
    }

    // this.loginService.getLoggedInUserDetails().subscribe(
    //   (data) => {
    //     if (!data) {
    //       this.router.navigate(['/login']);
    //       return false;
    //     }

    //     if (userId !== data.adminId) {
    //       this.router.navigate(['/login']);
    //       return false;
    //     }

    //   },
    //   (err: HttpErrorResponse) => {
    //     if (err.error instanceof Error) {
    //       console.log('An error occurred: ', err.error.message); // client
    //     } else {
    //       console.log(`Backend returned code ${err.status}, body was: ${err.error}`); // server
    //     }
    //     this.router.navigate(['/login']);
    //   }
    // );
    return true;
    // console.log(this.authService.isAuthenticated());

    // if (!this.authService.isAuthenticated()) {
    //   console.log(this.router.url);

    //   this.authService.setRedirectUrl(this.router.url);
    //   this.router.navigate(['login']);
    //   return false;
    // }
    // return true;
  }
}
