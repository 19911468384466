import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DefaultService } from '../default.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ServiceProviderService {

    constructor(private http: HttpClient, private defaultService: DefaultService) { }
    getSPWaitList() {
        return this.http.get<any>('souqh-waitlist/sp-details');
    }
    getStoreFrontList() {
        return this.http.get<any>('service-provider/storefront/all');
    }
    getEmailPreference(serviceProviderId) {
        return this.http.get<any>('email-preferences/email-preference/'+serviceProviderId);
    }
    getFreeTrialDays(email: string, numberOfFreeTrialDays: number) {
        return this.http.put<any>(`admin/provide-free-trial-days-to-sp?email=${email}&numberOfFreeTrialDays=${numberOfFreeTrialDays}`, {});
    }
    changeSPNotificationtoSummaryType(data,serviceProviderId) {
        return this.http.put<any>(`email-preferences/new-email-preference/${serviceProviderId}?emailPreferenceRequest=`+data,'');
    }
    sendSouqhVerificationEmail(emailId) {
        return this.http.put<any>(`verify/email/send/${emailId}`,'');
    }
    updateStoreFrontStatus(serviceProviderId, status, message) {
        return this.http.post(`admin/approve/sp/profile/request/${serviceProviderId}/${status}`, { "comment": message,"noOfFreeTrialDays":0});
    }
    approveStoreFront(comment, email, noOfFreeTrialDays,sendEmail) {
        return this.http.post<any>(`admin/approve/sp/profile-approval/request/emails`, [{ "comment": comment,"email": email,"numberOfFreeTrialDays": noOfFreeTrialDays,"sendMail":sendEmail}]);
    }
    impersonateModal(email) {
        return this.http.post<any>(`admin/login-as-sp`, { "email": email });
    }
    sendVerification(email) {
        return this.http.post<any>(`signnow/verification/${email}`, { "email": email });
    }
    sendEmail(emailId) {
        console.log("emailId", emailId)
        emailId = encodeURIComponent(emailId);
        return this.http.get<any>(`signnow/password/${emailId}`);
    }
    getAccountManagement() {
        return this.http.get<any>(`service-provider/getAll`);
    }
    getExpandedData(serviceProviderId) {
        return this.http.get<any>(`service-provider/team-member/${serviceProviderId}`)
            .pipe(map((data: any) => data.result),
                catchError(error => { return throwError('Its a Trap!') })
            );;
    }

    getReviewsData(storefrontId) {
        return this.http.get<reviews>(`admin/all/flagged/reviews?storefrontId=` + storefrontId);
    }
    saveApproveddData(approveData) {
        return this.http.post<reviews>(`admin/approve/flag-review`, JSON.stringify(approveData));
    }
    // deleteReview(deleteReviewData,reviewId) {
    //     return this.http.put<reviews>(`admin/delete-flaggedReview/${reviewId}`,deleteReviewData);
    // }
    deactivateAccountManagement(accountUserId, accountDeactivatedObject) {
        return this.http.put<reviews>(`service-provider/deactivate-client/${accountUserId}`, accountDeactivatedObject);
    }
    disabled2FA(userId,value) {
        return this.http.put<any>(`user/update2fa/${userId}/${value}`, '');
    }
    deleteServiceProvider(serviceProviderId, data) {
        return this.http.put<any>(`service-provider/delete-serviceProvider/${serviceProviderId}`, { "deletedByName": data.deleteByName, "reason": data.reason });
    }
    changeToDraftMode(email){
        let status="DRAFT";
        return this.http.put<any>(`admin/change-store-front-status-to-draft-or-requested?email=${email}&status=${status}`,{}); 
    }
   
}   