import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SortCriteria } from 'src/app/theme/layouts/pipes/sort.pipe';
import { ExcelService } from 'src/app/_services/excel.service';
import { DefaultService } from '../../default.service';
import { HomeBuyerService } from '../../homebuyer/homebuyer.service';
import { ServiceProviderService } from '../../serviceprovider/serviceprovider.service';
import { DashboardService } from '../dashboard.service';
export interface PeriodicElement {
  category: string;
  firstName: string;
  email: string;
  journeyStatus: string;
}

@Component({
  selector: 'app-referral-statistics',
  templateUrl: './referral-statistics.component.html',
  styleUrls: ['./referral-statistics.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReferralStatisticsComponent implements OnInit {
  hero = {};
  modalReference: any;
  @ViewChild('table', { static: false }) table: MatTable<any>;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  @ViewChild('closebuttonHold') closebuttonHold;
  @ViewChild('closebuttonReject') closebuttonReject;
  @ViewChild('closebuttonApprove') closebuttonApprove;
  @ViewChild(MatSort) sort: MatSort;
  loader = false;
  myControl = new FormControl();
  dataSource;
  dataSourceHome;
  type;
  sizes;
  options;
  sortedData: PeriodicElement[];
  columnsToDisplayHomeBuyer = ['icon', 'categoryHome', 'countHome'];
  columnsToDisplay = ['icon', 'categoryService', 'countService'];
  expandedElement: PeriodicElement | null;
  filteredOptions: Observable<PeriodicElement[]>;
  IsmodelShowReject: boolean;
  IsmodelShowHold: boolean;
  storeForntListData;
  currentStoreId;
  public criteria: SortCriteria;
  status;
  storeFrontDataMap: Map<number, any> = new Map<number, any>();
  storeFrontData;
  ELEMENT_DATA: PeriodicElement[];
  reactiveForm: FormGroup;
  myForm: FormGroup;
  formSubmitted = false;
  public addShopFormGroup: FormGroup;
  linkKey: any;
  closeResult: string;
  disableApprovedButton: boolean;
  emailForVerification: any;
  sendVerificationData: any;
  sendEmailData: string;
  sendEmail: boolean;
  sendVerificationButton: boolean;
  form = new FormGroup({
    filter: new FormControl,
    sizes: new FormControl
  });
  homeBuyerRefferalArray: any;
  serviceProviderRefferalArray: any;
  constructor(private modalService: NgbModal, private fb: FormBuilder, private defaultService: DefaultService, public dialog: MatDialog, public toastr: ToastrService, private dashboardService: DashboardService, private builder: FormBuilder) {

    // this.createForm();

  }

  ngOnInit() {
    this.criteria = {
      property: "category",
      descending: false
    };
    this.dashboardService.getServiceHomeBuyerRefferal().subscribe(
      (data) => {
        console.log("getServiceHomeBuyerRefferal....................", data.result)
        if (data != null) {
          this.homeBuyerRefferalArray = data.result;
          this.dataSourceHome = new MatTableDataSource(this.homeBuyerRefferalArray);
        }
        this.dataSourceHome.filterPredicate = (
          data: PeriodicElement,
          filter: string
        ) => {
          const obj = JSON.parse(filter);
          let find: boolean = !obj.filter && !obj.size;
          if (obj.filter) {
            const value =
              data.category;
            find = value.toString().toLowerCase().indexOf(obj.filter.toLowerCase()) >= 0;
          } else find = true;
          return find;
        };
      }
    )
    this.form.valueChanges.subscribe(res => {    
      this.dataSourceHome.filter = JSON.stringify(res);
    });

    this.dashboardService.getServiceProviderRefferal().subscribe(
      (data) => {
        console.log("getServiceProviderRefferal....................", data.result)
        if (data != null) {
          this.serviceProviderRefferalArray = data.result;
          this.dataSource = new MatTableDataSource(this.serviceProviderRefferalArray);
        }
        this.dataSource.filterPredicate = (
          data: PeriodicElement,
          filter: string
        ) => {
          const obj = JSON.parse(filter);
          let find: boolean = !obj.filter && !obj.size;
          if (obj.filter) {
            const value =
              data.category;
            find = value.toString().toLowerCase().indexOf(obj.filter.toLowerCase()) >= 0;
          } else find = true;
          return find;
        };
      }
    )
    this.form.valueChanges.subscribe(res => {    
      this.dataSource.filter = JSON.stringify(res);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceHome.filter = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();

}
  validationErrorExists() {
    return ((this.formSubmitted || this.addShopFormGroup.dirty) && !this.addShopFormGroup.valid);
  }
  isChecked(field: string, value: string) {
    const control = this.form.get(field);
    return control && control.value && control.value.indexOf(value) >= 0;
  }
  change(list: any[], field: string, value: string, isChecked: boolean) {
    const control = this.form.get(field);
    const oldValue = control ? control.value || [] : [];
    if (control) {
      if (!isChecked) {
        const newValue = oldValue.filter((x: string) => x != value);
        control.setValue(newValue.length > 0 ? newValue : null);
      } else {
        const newValue = list.filter(
          x => oldValue.indexOf(x) >= 0 || x == value
        );
        control.setValue(newValue);
      }
    }
  }
  sortDataSource() {
    this.dataSource.sort = this.sort;
    this.dataSourceHome.sort = this.sort;
  }
}