import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body",
    templateUrl: "./default.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class DefaultComponent implements OnInit {
    public iconOnlyToggled = false;
    public sidebarToggled = false;
    constructor(private router: Router) { }

    ngOnInit() {
        this.toggleSidebar();

    }
    toggleSidebar() {
        let body = document.querySelector('body');
        if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
            this.iconOnlyToggled = !this.iconOnlyToggled;
            if (this.iconOnlyToggled) {
                body.classList.add('sidebar-icon-only');
            } else {
                body.classList.add('sidebar-icon-only');
            }
        } else {
            this.sidebarToggled = !this.sidebarToggled;
            if (this.sidebarToggled) {
                body.classList.add('sidebar-hidden');
            } else {
                body.classList.remove('sidebar-hidden');
            }
        }
    }

}
