import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DefaultService } from '../default.service';

@Injectable()
export class HomeBuyerService {

    constructor(private http: HttpClient, private defaultService: DefaultService) { }
    getHBWaitList() {
        return this.http.get<any>('souqh-waitlist/hb-details');
    }
    getAccountManagementData() {
        return this.http.get<any>('home-buyer/getAll');
    }
    sendVerification(email) {
        return this.http.post<any>(`signnow/verification/${email}`, { "email": email });
    }
    deleteHomebuyer(homeBuyerId,data) {
        return this.http.put<any>(`user/delete-homebuyer/${homeBuyerId}`,  { "deletedByName": data.deleteByName,"reason": data.reason});
    }
    sendEmail(emailId) {
        console.log("emailId", emailId)
        emailId = encodeURIComponent(emailId);
        return this.http.get<any>(`signnow/password/${emailId}`);
    }
    sendSouqhVerificationEmail(emailId) {
        return this.http.put<any>(`verify/email/send/${emailId}`,'');
    }
}


