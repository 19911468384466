import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from '../../layouts/layout.module';
import { DefaultComponent } from '../default/default.component';
import { LoginComponent } from './login.component';
import { LoaderModule } from '../loader/loader.module';
import { LoginService } from './login.service';

const routes: Routes = [
  {
    'path': '',
    'component': DefaultComponent,
    'children': [
      {
        'path': '',
        'component': LoginComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    LayoutModule,
    FormsModule,
    LoaderModule,
  ], exports: [
    RouterModule,
  ], declarations: [
    LoginComponent,
  ], providers: [
    LoginService
  ]
})

export class LoginModule {
}