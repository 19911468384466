import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ThemeComponent } from './theme/theme.component';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { DashboardModule } from './theme/pages/default/dashboard/dashboard.module';
import { LayoutModule } from './theme/layouts/layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { LoginModule } from './theme/pages/login/login.module'
import { ErrorInterceptor } from './api-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DefaultService } from './theme/pages/default/default.service';
import { AuthGuard } from './auth/auth.guard';
import { LoaderModule } from "./theme/pages/loader/loader.module";
import { AuthService } from './auth/auth.service';
import { ServiceProviderService } from './theme/pages/default/serviceprovider/serviceprovider.service';
import { HomeBuyerService } from './theme/pages/default/homebuyer/homebuyer.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ExcelService } from './_services/excel.service';
import { GrdFilterPipe } from './theme/layouts/pipes/grd-pipe';
import { SortPipe } from './theme/layouts/pipes/sort.pipe';
import { RecaptchaModule } from 'ng-recaptcha';
import { DashboardService } from './theme/pages/default/dashboard/dashboard.service';
import { ConfirmDialogComponent } from './theme/pages/default/confirm-dialog/confirm-dialog.component';
import { PortalManagementModule } from './theme/pages/default/portal-management/portal-management.module';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    AppComponent,
    GrdFilterPipe,
    SortPipe,
    ThemeComponent,
    ConfirmDialogComponent],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeRoutingModule,
    DashboardModule,
    ChartsModule,
    LoaderModule,
    LoginModule,
    HttpClientModule,
    RecaptchaModule,
    PortalManagementModule,
    MatDialogModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ThemeService, DefaultService, ServiceProviderService, ExcelService, HomeBuyerService,
    AuthGuard, DashboardService,
    AuthService, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
