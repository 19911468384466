import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../auth/auth.service';

import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  loader = false;
  errorMessage;
  constructor(private router: Router, private loginService: LoginService, private authService: AuthService) { }

  ngOnInit() {
    sessionStorage.clear();

  }
  authenticate() {
    this.loader = true;
    let main_headers = {}
    this.loginService.adminLogin(this.email, this.password).subscribe(
      (res) => {
        const keys = res.headers.keys();
        let headers = keys.map(key => {
          `${key}: ${res.headers.get(key)}`
          main_headers[key] = res.headers.get(key)
        }
        );
        sessionStorage.setItem('authToken', main_headers['access_token'])
        sessionStorage.setItem('refreshToken', main_headers['refresh_token'])
        if (res.body.result != null) {
          sessionStorage.setItem("userId", res.body.result.userId);
          sessionStorage.setItem("adminData", JSON.stringify(res.body.result));
          this.router.navigate(['/dashboard/kpi']);
          this.loader = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.loader = false;
        if (err.status == 403)
          this.errorMessage = err.error.message;
        else
          this.errorMessage = "Something went wrong. Please try again";


        if (err.error instanceof Error) {
          console.log('An error occurred: ', err.error.message); // client
        } else {
          console.log(`Backend returned code ${err.status}, body was: ${err.error}`); // server
        }
        // swal('Error!', 'Something went wrong. Please try again.', 'error');

      }
    );

  }

}
