import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authenticated = false;
  private redirectUrl: string;

  constructor(private router: Router) { }

  // public setRedirectUrl(url: string) {
  //   this.redirectUrl = url;
  // }

  // public auth(data): void {


  //   if (data.userId != null || data.userId != undefined) {
  //     this.authenticated = true;
  //     this.redirectUrl = this.redirectUrl === undefined ? '/' : this.redirectUrl;
  //     this.router.navigate([this.redirectUrl]);
  //     sessionStorage.setItem("UserId", data.userId);
  //     sessionStorage.setItem("adminData", JSON.stringify(data));
  //     this.router.navigate(['/dashboard']);
  //   }
  //   else {
  //     this.authenticated = false;
  //     this.router.navigate(['/login']);
  //   }
  // }
  getToken(): string {
    return sessionStorage.getItem('authToken');    
  }
  // public isAuthenticated(): boolean {
  //   return true;//this.authenticated;
  // }
}