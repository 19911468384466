import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SubMenuComponent } from './submenu/submenu.component';
import { ContentAnimateDirective } from './directives/content-animate.directive';
import { DefaultComponent } from '../pages/default/default.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        SpinnerComponent,
        SubMenuComponent,
        DefaultComponent,
        ContentAnimateDirective
    ],
    exports: [
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        SpinnerComponent,
        SubMenuComponent,
        DefaultComponent,
        ContentAnimateDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
         MatDialogModule,
        MatIconModule,
        MatSlideToggleModule,
    ]
})
export class LayoutModule {
}