import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../theme/pages/login/login.component';
import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
    {
        'path': '',
        'component': ThemeComponent,
        'canActivate': [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./pages/default/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'serviceprovider',
                loadChildren: () => import('./pages/default/serviceprovider/serviceprovider.module').then(m => m.ServiceProviderModule),
            },
            {
                path: 'homebuyer',
                loadChildren: () => import('./pages/default/homebuyer/homebuyer.module').then(m => m.HomeBuyerModule),
            },
            {
                path: 'featuringstorefront',
                loadChildren: () => import('./pages/default/featuringstorefront/featuringstorefront.module').then(m => m.FeaturingstorefrontModule),
            },
            {
                path: 'adminprofile',
                loadChildren: () => import('./pages/default/admin-profile/admin-profile.module').then(m => m.AdminProfileModule),
            },
            {
                path: 'portal-management',
                loadChildren: () => import('./pages/default/portal-management/portal-management.module').then(m => m.PortalManagementModule),
            },
            {
                path: 'messaging',
                loadChildren: () => import('./pages/default/messaging/messaging.module').then(m => m.MessagingModule),
            },
            {
                'path': '**',
                component: LoginComponent
            }
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule { }