import { Component, OnInit } from '@angular/core';
import { ExcelService } from 'src/app/_services/excel.service';


interface ProductUsageStatistic {
  pageName: string;
  userType: string;
  traffic: number;
  usage: string;
}

@Component({
  selector: 'app-product-usage-statistics',
  templateUrl: './product-usage-statistics.component.html',
  styleUrls: ['./product-usage-statistics.component.scss']
})

export class ProductUsageStatisticsComponent implements OnInit {
  productUsageStatistics: ProductUsageStatistic[] = [];
  
  constructor(private excelService: ExcelService) { }

  ngOnInit(): void {
    this.productUsageStatistics = [
      {
        pageName: 'Page 1',
        userType: 'Home Buyer',
        traffic: 100,
        usage: 'High',
      },
      {
        pageName: 'Page 2',
        userType: 'Home Buyer',
        traffic: 10,
        usage: 'Low',
      },
      {
        pageName: 'Page 3',
        userType: 'Service Provider',
        traffic: 1000,
        usage: 'High',
      },
      {
        pageName: 'Page 4',
        userType: 'Service Provider',
        traffic: 1,
        usage: 'Low',
      },
      {
        pageName: 'Page 5',
        userType: 'Service Provider',
        traffic: 75,
        usage: 'Medium',
      }
    ]
  }

  exportAsXLSX() {
    this.excelService.exportAsExcelFile(this.productUsageStatistics, 'Product Usage Statistics');
  }
}
