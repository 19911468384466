import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, AfterViewInit } from '@angular/core';
declare let jQuery: any;
@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SubMenuComponent implements OnInit, AfterViewInit {
  dashboardMenu = true;
  spMenu = false;
  homeBuyer = false;
  featuringstorefront = false;
  adminprofile = false;
  portalManagement = false;
  notification = false;
  messaging = false
  constructor() { }

  ngOnInit() {
    var URL = document.location.href;
    if (URL.includes("dashboard")) {
      this.dashboardMenu = true;
      this.spMenu = false;
      this.homeBuyer = false;
      this.featuringstorefront=false;
      this.adminprofile = false;
      this.portalManagement = false;
      this.notification = false;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.dashbaord').addClass("activeSection");
    }
    else if (URL.includes("serviceprovider")) {
      this.dashboardMenu = false;
      this.spMenu = true;
      this.homeBuyer = false;
      this.featuringstorefront = false;
      this.adminprofile = false;
      this.portalManagement = false;
      this.notification = false;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.store').addClass("activeSection");
    }
    else if (URL.includes("homebuyer")) {
      this.dashboardMenu = false;
      this.spMenu = false;
      this.homeBuyer = true;
      this.featuringstorefront=false;
      this.portalManagement = false;
      this.adminprofile = false;
      this.notification = false;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.home').addClass("activeSection");
    }
    else if (URL.includes("featuringstorefront")) {
      this.dashboardMenu = false;
      this.spMenu = false;
      this.homeBuyer = false;
      this.portalManagement = false;
      this.featuringstorefront=true;
      this.notification = false;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.storefront').addClass("activeSection");
    }
    else if (URL.includes("adminprofile")) {
      this.dashboardMenu = false;
      this.spMenu = false;
      this.homeBuyer = false;
      this.adminprofile=true;
      this.portalManagement = false;
      this.notification = false;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.admin').addClass("activeSection");
    }
    else if (URL.includes("portal-management")) {
      this.dashboardMenu = false;
      this.spMenu = false;
      this.homeBuyer = false;
      this.portalManagement = true;
      this.notification = false;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.portal-management').addClass("activeSection");
    }
    else if (URL.includes("notification")) {
      this.dashboardMenu = false;
      this.spMenu = false;
      this.homeBuyer = false;
      this.portalManagement = false;
      this.notification = true;
      this.messaging = false;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.notification').addClass("activeSection");
    }
    else if (URL.includes("messaging")) {
      this.dashboardMenu = false;
      this.spMenu = false;
      this.homeBuyer = false;
      this.portalManagement = false;
      this.notification = false;
      this.messaging = true;
      jQuery('.nav-link').removeClass("activeSection");
      jQuery('.messaging').addClass("activeSection");
    }
  }
  ngAfterViewInit() {
    jQuery(document).on('click', '.makeStyles-listStyle-29', function () {
      jQuery(".makeStyles-listStyle-29").removeClass("makeStyles-active-35");
      jQuery(this).addClass("makeStyles-active-35");
    });

  }

}
