import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Color, Label } from 'ng2-charts';
import { DashboardService } from '../dashboard.service';
import { delay } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaceUnderscorePipe } from './replace-underscore.pipe';

export interface SpstatsTableData {
  StorefrontName: string;
  LeadsReceived: number;
  ActiveClients: number;
  AvgResponseTime: number;
  StorefrontVisits: number;
  DocumentShared: number;
  ReferralsSent: number;
}

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {
  public chartData: any[] = [];
  public chartData2: any[] = [];
  refresh = false;
  value = 15;
  loader = false;
  count: any;
  getSpstatsTableData: any;
  countServiceProvider: any;
  planUsage: any;
  planUsageArray: any;
  activeHomeBuyerStages: any;
  barChartData: any;
  public doughnutChartColors: Color[] = [{
    backgroundColor: ['rgba(188, 72, 157, 1)', 'rgba(242, 64, 224, 1)',
      'rgba(233, 139, 217, 1)',
      'rgba(236, 173, 226, 1)',
      'rgba(239, 210, 234, 1)']
  }];
  public doughnutChartLegend: boolean = false;
  public doughnutChartHomeColors: Color[] = [{
    backgroundColor: ['#47A7A7', '#7BCECE', '#71CACA', '#DCEAEA']
  }];
  spCategoryArray: any;
  spCategoryHomeBuyerArray: any;
  displayedColumns: string[] = ['StorefrontName', 'LeadsReceived', 'ActiveClients', 'CompletedClients', 'AvgResponseTime', 'StorefrontVisits', 'DocumentShared', 'ReferralsSent'];
  dataSource;
  ELEMENT_DATA: SpstatsTableData[];
  @ViewChild(MatSort) sort: MatSort;
  executed: boolean = false;
  delayInterval;
  filterByService: any;
  filterByTeam: any;
  statisticsArray: any;
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.dashboardService.getStatisticsTable().subscribe(
      (data) => {
        if (data != null) {
          let rawData = data.result;
          let dataValues = []; //For values
          let dataKeys = []; //For keys
          for (let key in rawData) {   //Pay attention to the 'in'
            dataValues.push(rawData[key]);
            dataKeys.push(key);
          }
          for (let d of dataValues) {
          }
          this.statisticsArray = dataValues
        }
      }
    )
    this.executed = false;
    this.dashboardService.getActiveHomeBuyerCount().subscribe(
      (data) => {
        if (data != null) {
          this.count = data.result;
        }
      }
    )
    this.dashboardService.getActiveHomeBuyerStages().subscribe(
      (data) => {
        if (data != null) {
          if (data) {
            console.log("getActiveHomeBuyerStages....................", data.result)
            this.activeHomeBuyerStages = data.result;
            for (var i = 0; i < this.activeHomeBuyerStages.length; i++) {
              this.chartData2.push(this.activeHomeBuyerStages[i].count);
              this.chartLabels2.push(this.activeHomeBuyerStages[i].category);
              this.chartLabels2[i] = this.toTitleCase(this.chartLabels2[i]) // 👉️ "A B C"
            }
          }
        }
      }
    )
    this.dashboardService.getCountOfServiceProvider().subscribe(
      (data) => {
        console.log("getCountOfServiceProvider....................", data.result)
        if (data != null) {
          this.countServiceProvider = data.result;
        }
      }
    )

    this.dashboardService.getCountOfSpCategory().subscribe(
      (data) => {
        console.log("getCountOfSpCategory.....................", data.result)
        if (data != null) {
          this.spCategoryArray = data.result;
          console.log("data dashboard ......................", data.result)
        }
      }
    )
    this.dashboardService.getCountOfHomeBuyerSpCategory().subscribe(
      (data) => {
        if (data != null) {
          this.spCategoryHomeBuyerArray = data.result;
        }
      }
    )
    this.dashboardService.getCountOfSpPlans().subscribe(
      (data) => {
        if (data) {

          console.log("getCountOfSpPlans......................", data.result)
          this.planUsageArray = data.result;
          // TEAM_MEMBER
          this.filterByTeam = this.planUsageArray.filter(function (v, i) {
            if (v.userRole.indexOf('TEAM_MEMBER') >= 0) {
              return true;
            } else false;
          });
          console.log('filter by team_member', this.filterByTeam);
          // SERVICE_PROVIDER
          this.filterByService = this.planUsageArray.filter(function (v, i) {
            if (v.userRole.indexOf('SERVICE_PROVIDER') >= 0) {
              return true;
            } else false;
          });


          for (var i = 0; i < this.planUsageArray.length; i++) {
            if (this.planUsageArray[i].planCount) {
              this.chartData.push(this.planUsageArray[i].planCount);
              this.chartLabels.push(this.planUsageArray[i].planName);
              console.log('filter by SERVICE_PROVIDER', this.planUsageArray[i]);
            }
          }
        }
        console.log('chartData', this.chartData);
      }
    )

  }
  toTitleCase(str) {
    const arr = str.split('_');
    const result = [];
    for (const word of arr) {
      result.push(word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
    }
    return result.join(' ');
  }
  filterByValue(arrayOfObject, term) {
    var ans = arrayOfObject.filter(function (v, i) {
      if (v.userRole.indexOf(term) >= 0) {
        return true;
      } else false;
    });
    console.log('filter by team_member', ans);
  }


  filterColumn = ['Pro', 'Premium'];
  form = new FormGroup({
    filter: new FormControl(),
    col: new FormControl()
  });
  // doughnut 1
  public chartType: string = 'doughnut';
  public chartLabels: Label[] = [];

  sizes = ['Find Your Home', 'Fund Your Home', 'Close into your Home', 'Move into your Home'];
  public chartOptions: any = {
    pieceLabel: {
      render: function (args) {
        const label = args.label,
          value = args.value;
        return label + ': ' + value;
      }
    }
  }
  // doughnut 2

  public chartLabels2: Array<string> = []
  public chartOptions2: any = {
    pieceLabel: {
      render: function (args) {
        const label = args.label,
          value = args.value;
        return label + ': ' + value;
      }
    }
  }
  isChecked(field: string, value: string) {
    const control = this.form.get(field);
    return control && control.value && control.value.indexOf(value) >= 0;
  }
  change(list: any[], field: string, value: string, isChecked: boolean) {
    const control = this.form.get(field);
    const oldValue = control ? control.value || [] : [];
    if (control) {
      if (!isChecked) {
        const newValue = oldValue.filter((x: string) => x != value);
        control.setValue(newValue.length > 0 ? newValue : null);
      } else {
        const newValue = list.filter(
          x => oldValue.indexOf(x) >= 0 || x == value
        );
        control.setValue(newValue);
      }
    }
  }
  // callGetSpstatsTableWithDelay(refresh, time) {
  //   this.loader = true;
  //   console.log(refresh, 'refresh');
  //   this.dashboardService.getspstatsTable(refresh).subscribe(
  //     (data) => {
  //       console.log(data.result.length, 'length');

  //       if (data.result.length > 0) {
  //         // if (this.delayInterval) {
  //         //   this.delayInterval.clear()
  //         // }
  //         console.log("getspstatsTable......................", data.result, data.result.length > 0)
  //         this.getSpstatsTableData = data.result;
  //         let arr = [];
  //         for (let i = 0; i < this.getSpstatsTableData.length; i++) {
  //           let obj = {
  //             StorefrontName: data.result[i].businessName,
  //             LeadsReceived: data.result[i].keyStatiticsLeadDto.totalCount,
  //             ActiveClients: data.result[i].keyStatiticsActiveClientDto.totalCount,
  //             CompletedClients: data.result[i].keyStatiticsCompletedDto.totalCount,
  //             AvgResponseTime: data.result[i].keyStatisticsResponseTime.totalResponseTime,
  //             StorefrontVisits: data.result[i].storeFrontVisit,
  //             DocumentShared: data.result[i].keyStatiticsEsignDocumentDto.totalCount,
  //             ReferralsSent: data.result[i].keyStatiticsReferralsDto.totalCount
  //           }
  //           arr.push(obj);
  //         }
  //         this.ELEMENT_DATA = arr;
  //         this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  //       }
  //       else {
  //         if (!this.executed) {
  //           this.executed = true;
  //           this.callGetSpstatsTableWithDelay(true, 0)
  //         } else {
  //           this.delayInterval = setInterval(() => {
  //             this.callGetSpstatsTableWithDelay(false, 0)
  //           }, 30000);
  //         }
  //       }
  //     }
  //   )
  //   this.loader = false;
  // }
  sortDataSource() {
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy() {
    // if (this.delayInterval) {
    //   this.delayInterval.clear()
    // }
  }


}


