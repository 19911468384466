
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private router: Router) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let npoServiceUrl = environment.apiServiceUrl;

    let req;
    if (request.url == "user/service-provider/signin") {
      req = request.clone({
        url: `${npoServiceUrl}/${request.url}`,
        headers: request.headers.set('Content-Type', 'application/json').set('Cache-Control', 'no-cache, no-store, must-revalidate')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      });
    }
    else {

      req = request.clone({
        url: `${npoServiceUrl}/${request.url}`,
        headers: request.headers.set('Content-Type', 'application/json').set('Cache-Control', 'no-cache, no-store, must-revalidate')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('Authorization', `${this.authenticationService.getToken()}`)
          
      });
  
    }



    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            localStorage.clear();
            this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
          }
        }
        return throwError(error);
      })
    )
  }

}
