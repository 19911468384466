import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleAndAccessComponent } from './role-and-access/role-and-access.component';
import { DefaultComponent } from '../default.component';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderModule } from "../../loader/loader.module";
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { EmailTemplatesConfigureComponent } from './email-templates/email-templates-configure/email-templates-configure.component';

const routes: Routes = [
  {
    "path": "",
    "component": DefaultComponent,
    "children": [
      {
        "path": "role-and-access",
        "component": RoleAndAccessComponent
      },
      {
        "path": "email-templates",
        "component": EmailTemplatesComponent
      },
      {
        "path": "email-templates/configure",
        "component": EmailTemplatesConfigureComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    LoaderModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    RoleAndAccessComponent,
    EmailTemplatesComponent,
    EmailTemplatesConfigureComponent
  ]
})
export class PortalManagementModule { }
