import { Route, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})

export class AdminProfileComponent implements OnInit {
  userData;
  constructor(public router: Router) {}

  ngOnInit(): void {}
  navigate() {
    this.router.navigateByUrl('/adminprofile/myprofile');
  }
 logout(){
  this.router.navigateByUrl('/login')
 }
}
