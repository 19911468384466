import { NotificationComponent } from './../../pages/default/notification/notification.component';
import { MatDialog,MatDialogRef,MatDialogConfig } from '@angular/material/dialog';
import { Component,  OnInit } from '@angular/core';
import { AdminProfileComponent } from '../../pages/default/admin-profile/admin-profile.component';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  userData;
  constructor( public popUpModal: MatDialog) {}
  openPopUp(){
    let dialModalRef = this.popUpModal.open(AdminProfileComponent);
    dialModalRef.updatePosition({  left: '60px', top:'600px'});
  }
  notificationPopUp(){
    let dialModalRef = this.popUpModal.open(NotificationComponent,{ height: '300px'});
    dialModalRef.updatePosition({  left: '60px', top:'400px'});
  }
  ngOnInit() {
   const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}
