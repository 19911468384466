import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revenue-statistics',
  templateUrl: './revenue-statistics.component.html',
  styleUrls: ['./revenue-statistics.component.scss']
})
export class RevenueStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
