import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ExcelService } from 'src/app/_services/excel.service';

export interface AdminUser {
  name: string;
  email: string;
  role: string;
  kpiMgmt: boolean;
  spMgmt: boolean;
  hbMgmt: boolean;
  portalMgmt: boolean;
  sfMgmt: boolean;
  errorMessage?: string;
}

const ADMIN_USER_DATA: AdminUser[] = [
  {name: 'Son of Harry', email: 'sonofharry@gmail.com', role: 'Admin', kpiMgmt: true, spMgmt: false, hbMgmt: true, portalMgmt: true, sfMgmt: false},
  {name: 'Brian Poe', email: 'brianpoeanoj@gmail.com', role: 'View Only', kpiMgmt: false, spMgmt: true, hbMgmt: false, portalMgmt: false, sfMgmt: true},
  {name: 'Aldrich Frazier', email: 'aldrichfrazier@gmail.com', role: 'Admin', kpiMgmt: false, spMgmt: false, hbMgmt: true, portalMgmt: true, sfMgmt: false},
  {name: 'Madison Nunez', email: 'madisonnunez@gmail.com', role: 'View Only', kpiMgmt: false, spMgmt: true, hbMgmt: false, portalMgmt: false, sfMgmt: true},
  {name: 'Smith', email: 'smith@gmail.com', role: 'View Only', kpiMgmt: false, spMgmt: true, hbMgmt: false, portalMgmt: true, sfMgmt: true},
  {name: 'Johnson', email: 'johnson@gmail.com', role: 'View Only', kpiMgmt: false, spMgmt: false, hbMgmt: false, portalMgmt: true, sfMgmt: true},
  {name: 'Williams', email: 'Williams@gmail.com', role: 'View Only', kpiMgmt: true, spMgmt: false, hbMgmt: true, portalMgmt: true, sfMgmt: true},
  {name: 'Madison Brown', email: 'madisonbrown@gmail.com', role: 'View Only', kpiMgmt: false, spMgmt: false, hbMgmt: false, portalMgmt: false, sfMgmt: true},
  {name: 'Happy Bowman  ', email: 'happybowman@gmail.com', role: 'Admin', kpiMgmt: false, spMgmt: false, hbMgmt: true, portalMgmt: false, sfMgmt: false}
];

const FETURE_NAME_VALUE_MAPPING = {
  'KPI Mgmt': 'kpiMgmt',
  'Service Provider Mgmt': 'spMgmt',
  'Home Buyer Mgmt': 'hbMgmt',
  'Portal Mgmt': 'portalMgmt',
  'Featuring Storefronts': 'sfMgmt'
}

@Component({
  selector: 'app-role-and-access',
  templateUrl: './role-and-access.component.html',
  styleUrls: ['./role-and-access.component.scss']
})
export class RoleAndAccessComponent implements OnInit {
  filterForm = new FormGroup({
    filter: new FormControl(),
    name: new FormControl()
  });
  filterColumns = {
      'role': {
        values: [],
        displayName: 'Role'
      }, 
      'features': {values: [
          'KPI Mgmt',
          'Service Provider Mgmt',
          'Home Buyer Mgmt',
          'Portal Mgmt',
          'Featuring Storefronts'
        ],
        displayName: 'Admin Portal KPI\'s'
      }
  }
  loader: boolean = false;
  adminUserList: AdminUser[] = []

  displayedColumns: string[] = ['name', 'role', 'kpiMgmt', 'spMgmt', 'hbMgmt', 'portalMgmt', 'sfMgmt', 'action'];
  dataSource = new MatTableDataSource<any>();
  pageNumber: number = 1;
  VOForm: FormGroup;
  isEditableNew: boolean = true;
  sortedAesc: boolean = false;
  initiateVOForm(): FormGroup {
    return this.fb.group({
      name: new FormControl(),
      email: new FormControl(),
      role: new FormControl('Admin'),
      kpiMgmt: new FormControl(false),
      spMgmt: new FormControl(false),
      hbMgmt: new FormControl(false),
      sfMgmt: new FormControl(false),
      portalMgmt: new FormControl(false),
      action: new FormControl('newRecord'),
      isEditable: new FormControl(false),
      isNewRow: new FormControl(false),
      errorMessage: new FormControl(),
    });
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private fb: FormBuilder,
    private _formBuilder: FormBuilder,
    private excelService: ExcelService) { }

  ngOnInit(): void {

    this.VOForm = this._formBuilder.group({
      VORows: this._formBuilder.array([])
    });

    this.VOForm = this.fb.group({
      VORows: this.fb.array(ADMIN_USER_DATA.map(adminUser => this.formFromAdminUser(adminUser)))
    }); // end of form group cretation
    this.loader = false;
    this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
    // this.dataSource.paginator = this.paginator;
    
    this.setDataFilters();
    this.sortByColumn('name');
  }

  // this function will enabled the select field for editd
  EditSVO(VOFormElement, i) {

    // VOFormElement.get('VORows').at(i).get('name').disabled(false)
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
    // this.isEditableNew = true;

  }

  // On click of correct button in table (after click on edit) this method will call
  SaveVO(VOFormElement, i) {
    // alert('SaveVO')
    let voFormElement = VOFormElement.get('VORows').at(i);
    let voFormElementValue = voFormElement.value;
    
    // check if atleast on permission is assigned
    if(!(voFormElementValue.kpiMgmt || voFormElementValue.spMgmt || voFormElementValue.hbMgmt || voFormElementValue.portalMgmt || voFormElementValue.sfMgmt)) {
      voFormElement.get('errorMessage').patchValue('Select Atleast one Permission for this User.');
    }
    else {
      voFormElement.get('errorMessage').patchValue('');
      voFormElement.get('isEditable').patchValue(true);
    }
  }

  // On click of cancel button in the table (after click on edit) this method will call and reset the previous data
  CancelSVO(VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
  }

  // @ViewChild('table') table: MatTable<PeriodicElement>;
  AddNewRow() {
    // this.getBasicDetails();
    const control = this.VOForm.get('VORows') as FormArray;
    control.insert(0,this.initiateVOForm());
    this.dataSource = new MatTableDataSource(control.controls)
    // control.controls.unshift(this.initiateVOForm());
    // this.openPanel(panel);
      // this.table.renderRows();
      // this.dataSource.data = this.dataSource.data;
  }

  filterOptionValue(filterOptionGroup: string, filterOptionValue: string) {
    return filterOptionGroup + ": " + filterOptionValue;
  }

  formFromAdminUser(adminUser: AdminUser) {
    return this.fb.group({
      name: new FormControl(adminUser.name),
      email: new FormControl(adminUser.email),
      role: new FormControl(adminUser.role),
      kpiMgmt: new FormControl(adminUser.kpiMgmt),
      spMgmt: new FormControl(adminUser.spMgmt),
      hbMgmt: new FormControl(adminUser.hbMgmt),
      sfMgmt: new FormControl(adminUser.sfMgmt),
      portalMgmt: new FormControl(adminUser.portalMgmt),
      action: new FormControl('existingRecord'),
      isEditable: new FormControl(true),
      isNewRow: new FormControl(false),
      errorMessage: new FormControl()
    })
  }

  isRowMatchingWithFilters(filter: any, dataRow: AdminUser) {
    let isNameMatched = (!filter.name) || (dataRow.name.toLowerCase().includes(filter.name.toLowerCase()))
    let isRoleMatched = filter.columnFilters.role.length < 1 || filter.columnFilters.role.includes(dataRow.role);
    let isFatureMatched = filter.columnFilters.features.length < 1

    if (!isFatureMatched) {
      let notMatchedFeature = filter.columnFilters.features.filter(featureName => {
        return !dataRow[FETURE_NAME_VALUE_MAPPING[featureName]]
      })
      isFatureMatched = notMatchedFeature.length < 1;
    }


    return isNameMatched && isRoleMatched && isFatureMatched;
  }

  filteredAdminUserData(filter: any) {
    return ADMIN_USER_DATA.filter((adminUser: AdminUser) => {
      return this.isRowMatchingWithFilters(filter, adminUser)
    });
  };

  setDataFilters() {
    this.adminUserList = ADMIN_USER_DATA;

    this.adminUserList.map(adminUser => {
      if(!this.filterColumns.role.values.includes(adminUser.role)) {
        this.filterColumns.role.values.push(adminUser.role)
      }
    })

    this.filterForm.valueChanges.subscribe(res => {
      let columnFilters = {
        'role': [],
        'features': []
      }
      if(res.filter){
        res.filter.map(filter => {
          let filterKey: string = filter.split(': ')[0]
          columnFilters[filterKey].push(filter.split(': ')[1])
        })
      }

      res.columnFilters = columnFilters;
      let filteredAdminUsersList = this.filteredAdminUserData(res);
      this.VOForm = this.fb.group({
        VORows: this.fb.array(filteredAdminUsersList.map(adminUser => this.formFromAdminUser(adminUser)))
      });
      this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
    });
  }

  exportAsXLSX() {
    this.excelService.exportAsExcelFile(this.adminUserList, 'admin_users_list');
  }

  sortByColumn(columnToBeSorted) {
    this.sortedAesc = !this.sortedAesc;
    let sortedAdminUsersList = ADMIN_USER_DATA.sort((adminUser1: AdminUser, adminUser2: AdminUser) => {
      return (adminUser1[columnToBeSorted] > adminUser2[columnToBeSorted]) ? (this.sortedAesc ? 1 : -1) : (this.sortedAesc ? -1 : 1);
    });
    this.VOForm = this.fb.group({
      VORows: this.fb.array(sortedAdminUsersList.map(adminUser => this.formFromAdminUser(adminUser)))
    });
    this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
  }
}
