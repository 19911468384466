import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  adminLogin(email: string, password: string) {
    let data = {
      email: email,
      password: password,
    }
    return this.http.post<any>('user/service-provider/signin', JSON.stringify(data), { 'headers': new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
  }

}
