import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LayoutModule } from '../../layouts/layout.module';
import { DefaultComponent } from '../default/default.component';
import { LoaderComponent } from './loader.component';


@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        FormsModule
    ], exports: [
        LoaderComponent
    ], declarations: [
        LoaderComponent
    ],
    bootstrap: [LoaderComponent]
})
export class LoaderModule {
}